import { useParams } from 'react-router-dom';
import BookingConfirmation from '../components/bookingconfirmation/BookingConfirmation';

const BookingConfirmationPage = () => {
	const { bookingGroupID } = useParams();

	return (
		<div className="page">
			<div className="page-header">
				<h1>Booking confirmation</h1>
			</div>
			<BookingConfirmation bookingGroupID={bookingGroupID} />
		</div>
	);
};

export default BookingConfirmationPage;
