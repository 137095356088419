import { Calendar, DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

import './Calendar.css';

const FUTURE_LIMIT_DAYS = 14;

const DailyCalendar = ({ handleDailySelect, isError }) => {

    return (
        <div>
            <Calendar
                className='custom-input'
                maxDate={new DateObject().add(
                    FUTURE_LIMIT_DAYS,
                    'days'
                )}
                numberOfMonths={1}
                multiple
                sort
                disable={true}  // Prevents user from closing the calendar
                format="DD MMM YYYY"
                render={<InputIcon />}
                monthYearSeparator="|"
                weekStartDayIndex={1}
                mapDays={({ date }) => {
                    const today = new DateObject();
                    const isWeekend = [0, 6].includes(
                        date.weekDay.index
                    );
                    const isPast = date.toDays() - today.toDays() < 0;
                    const isFuture =
                        date.toDays() - today.toDays() >
                        FUTURE_LIMIT_DAYS;

                    if (isPast || isFuture || isWeekend) {
                        return {
                            disabled: true,
                            style: {
                                color: '#ccc',
                                textDecoration: isPast
                                    ? 'line-through'
                                    : 'none',
                            },
                        };
                    }
                }}
                onChange={handleDailySelect}
            />
            {isError && (
                <p className='calendar-error'>Please select your dates</p>
            )}
        </div >
    );
};

export default DailyCalendar;