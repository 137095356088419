import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';

import './AvailabilitySummary.css';

/*
    Important: daily and monthly desks are treated a bit differently here.
    Daily desks are selected by date and the user can pick different desks for each day.
    A single monthly desk is selected for all the months the user chooses for the entire period.
*/

const Desk = ({ desk, onSelect, isSelected }) => {

    const idClass = `desk-${desk.id}`;
    const classNames = [
        idClass,
        isSelected ? 'selected' : '',
    ].join(' ').trim();

    return (
        <li key={desk.id} className="desk-item">
            <button
                onClick={() => onSelect(desk)}
                className={classNames}
            >
                Desk {desk.id}
            </button>
        </li>
    );
};

const AvailableDaily = ({ date, desks, onSelectDesk, selectedDesks }) => {

    // the date parameter here is a single date in a one-element array
    // this is required to match the date format for monthly bookings
    // format the date as "Day of the week, Day of the month Month Year"
    const formattedDate = format(parseISO(date[0]), "EEEE do MMMM yyyy");

    return (
        <div className='availability-summary-item desk-wrapper'>
            <h3>{formattedDate}</h3>
            {
                // handle the case when there is no desk available for the date
                !desks.length ? (
                    <p>No desk available for this period.</p>
                ) : (
                    <div className="available-date-panel">
                        <ul className="desk-list">
                            {desks.map((desk) => (
                                <Desk
                                    key={desk.id}
                                    desk={desk}
                                    onSelect={(desk) => onSelectDesk(date, desk)}
                                    isSelected={selectedDesks[date[0]] && selectedDesks[date[0]].id === desk.id}
                                />
                            ))}
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

const AvailableMonthly = ({ months, desks, onSelectDesk, selectedDesks }) => {

    // format the date range as "Month Year - Month Year"
    let formattedDate;
    if (months.length === 1) {
        formattedDate = format(parseISO(months[0]), "MMMM yyyy");
    } else {
        formattedDate = format(parseISO(months[0]), "MMMM yyyy") + " - " + format(parseISO(months[months.length - 1]), "MMMM yyyy");
    }

    return (
        <div className='availability-summary-item desk-wrapper'>
            <h3>{formattedDate}</h3>
            {
                // handle the case when there is no desk available for the date
                !desks.length ? (
                    <p>No desk available for this period.</p>
                ) : (
                    <div className="available-date-panel">
                        <ul className="desk-list">
                            {desks.map((desk) => (
                                <Desk
                                    key={desk.id}
                                    desk={desk}
                                    onSelect={(desk) => onSelectDesk(months, desk)}
                                    // can just check the first month in the list, as the desk ID is the same for all months
                                    isSelected={selectedDesks[months[0]] && selectedDesks[months[0]].id === desk.id}
                                />
                            ))}
                        </ul>
                    </div>
                )
            }
        </div>
    );
};

const AvailabilitySummary = ({
    bookingType,
    bookingDates,
    setFinalDesks,
    setTotalPrice,
    resetAfterTimeOutOrError
}) => {

    // local development uses env.development, production routes to /api
    const API_URL = process.env.REACT_APP_API_URL || "/api";

    const [availableDesks, setAvailableDesks] = useState([]);
    const [selectedDesks, setSelectedDesks] = useState({});

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    useEffect(() => {

        // reset the selected desks when the booking dates change
        setSelectedDesks({});
        setTotalPrice(0);

        if (!bookingDates || bookingDates.length === 0) {
            return;
        }
        // check if the booking dates are valid

        const fetchAvailableDesks = async () => {
            try {

                const payload = {
                    booking_dates: bookingDates,
                    booking_type: bookingType
                }

                const deskAvailabilityURL = `${API_URL}/desks/availability`;

                const response = await fetch(deskAvailabilityURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {

                    let apiErrorMsg = `Failed to check desk availability: ${response.status} ${response.statusText}`;

                    if (response.status === 400) {
                        apiErrorMsg = 'Bad Request: Please check the data and API call.';
                    }
                    else if (response.status === 404) {
                        apiErrorMsg = 'Resource error: The requested resource does not exist on the server.';
                    }
                    else if (response.status === 500) {
                        apiErrorMsg = 'Server Error: Please try again later.';
                    }
                    console.error(apiErrorMsg);
                    throw new Error(apiErrorMsg);
                }

                const data = await response.json();
                console.log('Available desks API response:', data);

                setAvailableDesks(data);

            } catch (error) {
                // catch any errors, i.e. ones not caught by the fetch call
                // and also the one thrown by the fetch call
                setApiErrorMessage('Apologies but something went wrong. Please try again later. ' + error.message);
                setIsErrorModalOpen(true);
                console.log('Error getting available desks: ', error.message);
            }
        };

        fetchAvailableDesks();
    }, [bookingDates]);


    const handleSelectDesk = (date, desk) => {

        if (bookingType === "daily") {
            // the date parameter here is a single date
            setSelectedDesks((prevSelectedDesks) => {

                const updatedDesks = { ...prevSelectedDesks };

                if (updatedDesks[date] && updatedDesks[date].id === desk.id) {
                    delete updatedDesks[date];
                } else {
                    updatedDesks[date] = desk;
                }

                // If no desks are selected (i.e deselection of a desk), 
                // return an empty object
                return Object.keys(updatedDesks).length === 0 ? {} : updatedDesks;

            });
        }
        else if (bookingType === "monthly") {

            // we assume the user can only pick one desk for the entire period
            // so we apply the same desk ID to each month
            setSelectedDesks((prevSelectedDesks) => {

                const updatedDesks = { ...prevSelectedDesks };

                date.forEach((d) => {
                    if (updatedDesks[d] && updatedDesks[d].id === desk.id) {
                        delete updatedDesks[d];
                    } else {
                        updatedDesks[d] = desk;
                    }
                });

                // If no desks are selected (i.e deselection of a desk), 
                // return an empty object
                return Object.keys(updatedDesks).length === 0 ? {} : updatedDesks;
            });
        }
        else {
            console.error('Invalid booking type:', bookingType);
        }

    };


    // update the form data with the selected desks
    useEffect(() => {

        // update the form data with the selected desks

        // check selected desks are not empty
        if (Object.keys(selectedDesks).length >= 0) {

            // calculate the total price
            let totalPrice = 0;
            for (const date in selectedDesks) {
                totalPrice += selectedDesks[date].price;
            }

            // update the form data with the selected desks and total price
            setTotalPrice(totalPrice);
            setFinalDesks(selectedDesks);

        }

    }, [selectedDesks]);


    return (
        <div>
            <div className='availability-summary-container'>
                {bookingType === "daily" && availableDesks.map((day) => (
                    <AvailableDaily
                        key={day.date}
                        date={day.date}
                        desks={day.desks}
                        onSelectDesk={handleSelectDesk}
                        selectedDesks={selectedDesks}
                    />
                ))}
                {bookingType === "monthly" && availableDesks.map((months) => (
                    <AvailableMonthly
                        key={months.date}
                        months={months.date}
                        desks={months.desks}
                        onSelectDesk={handleSelectDesk}
                        selectedDesks={selectedDesks}
                    />
                ))}
            </div>
            {isErrorModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <p>{apiErrorMessage}</p>
                        <button onClick={() => {
                            setIsErrorModalOpen(false);
                            resetAfterTimeOutOrError();
                        }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div >
    );
};

export default AvailabilitySummary;