import { Link } from 'react-router-dom';

const FAQPage = () => {

    return (
        <div className="page">
            <div className='page-header'>
                <h1>Good to know</h1>
            </div>
            <div className='page-content'>
                <p>
                    Before you book, it's worth knowing a bit more about how the space works. This section covers what's inside, what's nearby, and how things run day to day. For a more formal version of how various things are set up, take a look at our <Link to='/terms'>Terms of Service</Link>.
                </p>
                <h2>
                    What is Room 49?
                </h2>
                <p>
                    Room 49 is a co-working space in St. John's, Tunbridge Wells. Set in a Victorian building with an industrial-chic feel—exposed brick, black metal, bookshelves, plants, and plenty of natural light. It's designed for professionals who need a focused, spacious place to work.
                </p>
                <h2>
                    What's inside?
                </h2>
                <p>
                    Spacious desks, comfortable chairs, and plenty of natural light—plus overhead lighting designed to keep things bright without feeling harsh. The wooden flooring has underfloor heating, making it comfortable year-round. There's high-speed internet, a kitchenette, and a toilet. We'll usually have tea, coffee and milk available, with a kettle and filtered drinking water on tap throughout the day. And we've kept things simple on purpose with no fancy coffee machine—because we're lucky to have such fab coffee available from right next door or across the road! We also have a small basement meeting room with a projector, but this does not come with technical support.
                </p>
                <h2>
                    What's outside?
                </h2>
                <p>
                    John Street Car Park is just on the corner—with up to 100 spaces and affordable rates. Need coffee? Leaf & Bean is right next door, and there are plenty of other great spots along the road. If you want lunch out, grab a meal deal from Sainsbury's or Tesco, sit down for something more substantial at St John's Yard for a pub lunch, or head to Hidden Well for a bowl of yummy noodles.
                </p>
                <h2>What's the vibe?</h2>
                <p>
                    The soundproof shopfront is pretty key for cutting out the busy road noise. During the working day, the space is much quieter than a coffee shop, but being open-plan there's still plenty of life about—background noise from typing, calls, and general office activity. It's also an old building, so some day-to-day sounds do travel from the neighbouring properties.</p>
                <h2>
                    How can I book?
                </h2>
                <p>
                    You can <Link to='/booking'>book a desk</Link> Monday to Friday, 7am to 7pm—including bank holidays. Daily desks can be booked up to two weeks in advance. If a desk is still available after 10am on the day, it can be booked at a discounted rate. Monthly desks are for more regular use—you can book for up to six months at a time, with priority for the next six. If you've already reserved a desk, you can also book the private <Link to='/meeting'>meeting room</Link> downstairs for one-to-ones or quiet sessions. All desk bookings are final—since reserving a desk means it's unavailable to others, cancellations or changes are non-refundable. We're based in a Victorian building, so while some desks have plenty of space for a wheelchair, accessibility to the toilet and basement is limited.
                </p>
                <h2>
                    What's the internet like?
                </h2>
                <p>
                    At Room 49, you'll get fast, reliable Wi-Fi that's password-protected and designed with privacy in mind. Unlike typical public Wi-Fi, our network is set up to limit cross-device visibility — meaning other users can't easily see or connect to your device. If you've booked a monthly desk, you'll also have access to a wired Ethernet port. Each port is individually isolated from the rest of the network, offering extra peace of mind for sensitive work or video calls. While we've taken care to follow best practices for a secure setup, we still recommend using a VPN for added protection if you're handling particularly sensitive business data.
                </p>
                <h2>
                    Can I bring a guest (or pet)?

                </h2>
                <p>
                    If you need to bring a guest, you can <Link to='/meeting'>book the meeting room</Link> downstairs and let them in when they arrive. It's the right spot for any one-to-one conversations or catch-ups, rather than using the shared desk space upstairs. Pets aren't allowed—even the well-behaved ones. The same goes for children, including students. It's strictly for adults using the space to work, not for homework or study sessions.
                </p>
                <h2>
                    What's the etiquette?

                </h2>
                <p>
                    Room 49 is a focused, friendly place to work—and the open-plan setup gives it a great energy. But shared spaces only work if everyone's considerate. Please don't let anybody you're not expecting into the office. Use a headset and don't have meetings on speaker—same goes for music. Keep bags and other bits out of walkways so no one trips over your stuff. Only plug in low-power, work-related items—think laptops and phones, not heaters and cars.
                </p>
                <p>
                    You're welcome to use the kitchen, but please leave things as you find them and just be considerate of others. We've all worked somewhere where someone thought microwaving sardines was a good idea! You're welcome to keep lunch in the fridge, and when it's time to eat, you might grab lunch at your desk, perch at the kitchen stools, or head downstairs for a break—the basement's blocked for lunch 12-1.
                </p>
                <p>
                    Just a bit of common sense, really—that's the etiquette. It's all about keeping Room 49 a great place to work for the day, right up until we all vacate by 7pm.
                </p>
                <h2>
                    How are things kept secure?
                </h2>
                <p>
                    The office has security cameras, and door access is controlled via a code system. If you're the last to leave, it's your responsibility to check that the door has fully shut and secured behind you—this is essential to keeping the space safe for everyone. However, any personal belongings, whether laptops, bags, or equipment, are always left entirely at your own risk.
                </p>
            </div>
        </div>
    );
};

export default FAQPage;
