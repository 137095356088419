import React, { useState, useEffect } from 'react';

const HomePageBanner = () => {
	const images = [
		'/images/homepage_banner_1.jpg',
		'/images/homepage_banner_2.jpg',
		'/images/homepage_banner_3.jpg'
	];

	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex(
				(prevIndex) => (prevIndex + 1) % images.length
			);
		}, 5000); // Change image every 10 seconds
		return () => clearInterval(interval);
	}, [images.length]);

	return (
		<div className="banner">
			{images.map((image, index) => (
				<img
					key={index}
					src={image}
					alt={`HomePageBanner ${index + 1}`}
					className={`banner-image ${currentImageIndex === index ? 'active' : ''
						}`}
				/>
			))}
		</div>
	);
};

export default HomePageBanner;
