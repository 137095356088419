import { Calendar, DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

import './Calendar.css';

const FUTURE_LIMIT_MONTHS = 6;

const MonthlyCalendar = ({ handleMonthlySelect, isError }) => {

    return (
        <div>
            <Calendar
                className='custom-input'
                minDate={new DateObject()}
                maxDate={new DateObject().add(
                    FUTURE_LIMIT_MONTHS,
                    'months'
                )}
                onlyMonthPicker
                range
                sort
                format="MMM YYYY"
                render={<InputIcon />}
                onChange={handleMonthlySelect}
            />
            {isError && (
                <p className='calendar-error'>Please select your dates</p>
            )}
        </div>
    );
};

export default MonthlyCalendar;