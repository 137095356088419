import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Ensure you have the appropriate CSS file for styling

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpen]);

    return (
        <nav className="navbar">
            <div className='navbar-container'>
                {/* Left-aligned logo */}
                <div className="navbar-logo">
                    <Link to="/">
                        <img src="/images/room49_logo.png" alt="Room 49 logo" className="logo" />
                    </Link>
                </div>
                {/* Hamburger icon */}
                <div className={`navbar-hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <span className="hamburger-icon">&#9776;</span>
                </div>
                {/* Right-aligned links */}
                <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
                    <Link to="/booking">Booking</Link>
                    <Link to="/faq">Questions</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/about">About</Link>
                </div>
            </div>
            {isOpen && (
                <div className="navbar-modal">
                    <div className="navbar-modal-content">
                        <span className="close-button" onClick={toggleMenu}>&times;</span>
                        <Link to="/booking" onClick={toggleMenu}>Booking</Link>
                        <Link to="/faq" onClick={toggleMenu}>Questions</Link>
                        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
                        <Link to="/about" onClick={toggleMenu}>About</Link>
                        <Link to="/terms" onClick={toggleMenu}>Terms</Link>
                        <Link to="/terms#privacy" onClick={toggleMenu}>Privacy</Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;