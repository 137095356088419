import '../components/contact/Contact.css';

import ContactForm from '../components/contact/ContactForm';
import ContactMap from '../components/contact/ContactMap';

const ContactPage = () => {
    return (
        <div className="page">
            <div className='page-header'>
                <h1>Where to find us</h1>
            </div>
            <div className='page-content'>
                <p>
                    Room 49 is in St John's, just up from the centre of Tunbridge Wells. It's completely self-service—no front desk, no interruptions, no tours. The space is dedicated to getting work done, and the photos on the site show you exactly what to expect.
                </p>
            </div>
            <div className="contact-container">
                <ContactMap />
                <ContactForm />
            </div>
            <div className="contact-useful-points">
                <p>
                    <strong>Open:</strong> Weekdays from 7am to 7pm, including bank holidays, giving you flexibility to start early or work late.
                </p>
                <p>
                    <strong>Access:</strong> Secure doorcode system only, sent after booking to ensure a smooth, hassle-free self-check-in.
                </p>
                <p>
                    <strong>Security:</strong> The space is monitored by a state-of-the-art camera and alarm system for your peace of mind.
                </p>
                <p>
                    <strong>Parking:</strong> John Street Car Park is just a short walk away, offering convenient parking close to the office.
                </p>
                <p>
                    <strong>Surrounding:</strong> Shell, BP, Sainsbury's, Tesco, cafés, lunch spots, and a nursery are all nearby for convenience.
                </p>
            </div>
        </div>
    );
};

export default ContactPage;
