import { Link } from 'react-router-dom';

import './Footer.css';

const Footer = () => (
	<footer>
		<p>&copy; 2025 The Typing Rooms. All rights reserved.</p>
		<p>49 St Johns Rd, Tunbridge Wells, TN4 9TP</p>
		<p>
			<Link to="/terms">Terms of Service</Link> |{' '}
			<Link to="/terms#privacy">Privacy Policy</Link>
		</p>
	</footer>
);

export default Footer;
