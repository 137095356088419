import React, { useEffect, useState } from 'react';

import BookingConfirmationTable from './BookingConfirmationTable';
import './BookingConfirmation.css';

const BookingConfirmation = ({ bookingGroupID }) => {

    // local development uses env.development, production routes to /api
    const API_URL = process.env.REACT_APP_API_URL || "/api";

    const [bookingData, setBookingData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    useEffect(() => {
        const fetchBookingData = async () => {
            try {

                const bookingsURL = `${API_URL}/bookings/${bookingGroupID}`;

                const response = await fetch(`${bookingsURL}`);

                if (!response.ok) {

                    let apiErrorMsg = `Failed to get booking confirmation: ${response.status} ${response.statusText}`;

                    if (response.status === 400) {
                        apiErrorMsg = 'Bad Request: Please check the data and API call.';
                    }
                    else if (response.status === 404) {
                        apiErrorMsg = 'Resource error: The requested resource does not exist on the server.';
                    }
                    else if (response.status === 500) {
                        apiErrorMsg = 'Server Error: Please try again later.';
                    }
                    console.error(apiErrorMsg);
                    throw new Error(apiErrorMsg);

                }
                const data = await response.json();
                setBookingData(data);

            } catch (error) {
                // catch any errors, i.e. ones not caught by the fetch call
                // and also the one thrown by the fetch call
                setApiErrorMessage('Apologies but something went wrong. Please try again later. ' + error.message);
                setIsErrorModalOpen(true);
                console.log('Error submitting for payment: ', error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBookingData();
    }, [bookingGroupID]);

    // ---------------------------------------------------------------
    // TODO UPDATE THIS WITH PROPER DISPLAYS ...
    if (loading) return <p>Loading booking details...</p>;
    // ---------------------------------------------------------------

    return (
        <div className='booking-confirmation-container'>
            {bookingData ? (
                <div>
                    <h2>You're all set!</h2>
                    <p>Your workspace at Room 49 is booked.</p>
                    <div className='info-block'>
                        <p>
                            <strong>Name:</strong> {bookingData.first_name} {bookingData.last_name}
                        </p>
                        <p>
                            <strong>Email:</strong> {bookingData.email}
                        </p>
                    </div>
                    <BookingConfirmationTable
                        finalDesks={bookingData.booking_dates}
                        totalPrice={bookingData.total_price}
                        bookingType={bookingData.booking_type} />
                    <div className='info-block'>
                        <p>
                            <strong>Door: {bookingData.udm_pincode}</strong>
                        </p>
                        <p>
                            Enter the code into the keypad on the wall to the left of the door. The door will unlock for 10 seconds. Monthly customers will also get a separate email with a QR code for even easier access.
                        </p>
                    </div>
                    <div className='info-block'>
                        <p>
                            <strong>Wi-Fi: MtjS8GSsMdXy</strong>
                        </p>
                        <p>
                            Connect to the network named "room 49". This passcode gives you super fast internet access for the duration of your booking.
                        </p>
                    </div>
                    <div className='info-block'>
                        <p>
                            If you have any issues with the door code, Wi-Fi, or anything that stops you using the space, email <a href="urgent@thetypingrooms.co.uk">urgent@thetypingrooms.co.uk</a>
                        </p>
                    </div>
                    <div className='info-block'>
                        <h2>
                            Your desk awaits...
                        </h2>
                    </div>
                </div>
            ) : (
                <div>
                    <p>We are sorry but we could not find your booking confirmation.</p>
                </div>
            )
            }

            {
                isErrorModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <p>{apiErrorMessage}</p>
                            <button onClick={() => {
                                setIsErrorModalOpen(false);
                            }}>
                                Close
                            </button>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default BookingConfirmation;
