const BookingTypeButton = ({ onClick, isSelected, booking_type, button_text, description }) => {

    const buttonClass = isSelected ? 'button-container button-container-selected' : 'button-container';

    const bookingTypeClass = booking_type === 'daily' ? 'button-item daily-button' : 'button-item monthly-button';

    const price = booking_type === 'daily' ? (
        <>
            £20<span style={{ fontSize: "0.8em" }}>/day</span>
        </>) : (
        <>
            £250<span style={{ fontSize: "0.8em" }} >/month</span>
        </>
    );

    return (

        <button onClick={onClick} className={buttonClass}>
            <div className={bookingTypeClass}>
                <p>{button_text}</p>
            </div>
            <div className="description-item">
                <p>
                    {description}
                </p>
            </div>

            <div className="selection-item">
                <div role="button" tabIndex="0" className="button">
                    Select
                </div>
            </div>
            <div className="selection-item">
                <p>{price}</p>
            </div>
        </button >

    );
};

export default BookingTypeButton;
