import React, { useState, useEffect } from 'react';
import './Contact.css';

const ContactForm = () => {

    // local development uses env.development, production routes to /api
    const API_URL = process.env.REACT_APP_API_URL || "/api";

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [triggerValidation, setTriggerValidation] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (isButtonClicked) {
            // continuously validate form on input change
            setTriggerValidation(true);
        }
    };


    useEffect(() => {
        if (triggerValidation) {
            validateForm();
        }
    }, [triggerValidation]);


    const validateForm = () => {

        if (!isButtonClicked) {
            // capture first time button has been clicked
            setIsButtonClicked(true);
        }

        const errors = {};
        if (!formData.name) {
            errors.name = true;
        }
        if (!formData.email) {
            errors.email = true;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = true;
        }
        if (!formData.message) {
            errors.message = true;
        }
        setValidationErrors(errors);

        // determine if form is valid
        const isValid = Object.keys(errors).length === 0

        // Reset trigger after validation so it will be called again if set true
        if (triggerValidation)
            setTriggerValidation(false);

        return isValid;
    };


    const handleSubmit = async (e) => {

        if (!validateForm()) {
            return;
        }

        const payload = {
            name: formData.name,
            from_email: formData.email,
            message: formData.message,
        };

        try {

            const contactUsURL = `${API_URL}/contact`;

            const response = await fetch(contactUsURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {

                let apiErrorMsg = `Failed to post booking: ${response.status} ${response.statusText}`;

                if (response.status === 400) {
                    apiErrorMsg = 'Bad Request: Please check the data and API call.';
                }
                else if (response.status === 404) {
                    apiErrorMsg = 'Resource error: The requested resource does not exist on the server.';
                }
                else if (response.status === 500) {
                    apiErrorMsg = 'Server Error: Please try again later.';
                }
                throw new Error(apiErrorMsg);
            }

            const data = await response.json();
            console.log('Contact form response:', data);

            setIsModalOpen(true);


        } catch (error) {
            // catch any errors, i.e. ones not caught by the fetch call
            // and also the one thrown by the fetch call
            setApiErrorMessage('Apologies but something went wrong. Please try again later. ' + error.message);
            setIsErrorModalOpen(true);
            console.log('Error sending contact us message: ', error.message);
        }

    };


    const closeModal = () => {
        setIsModalOpen(false);
        setFormData({ name: '', email: '', message: '', isFormValid: false });
    };

    return (
        <div className="form-container">
            <div>
                <div className={'contact-form-input' + (validationErrors.name ? ' contact-error-icon' : '')}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={'contact-form-input ' + (validationErrors.email ? ' contact-error-icon' : '')}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={'contact-form-input' + (validationErrors.message ? ' contact-error-icon' : '')}>
                    <textarea
                        name="message"
                        placeholder="Your message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <div className="contact-button-container">
                    <button
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Send
                    </button>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <p>Thank you for your message</p>
                        <button onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            {isErrorModalOpen && (
                <div className="modal-cverlay">
                    <div className="modal-content">
                        <p>{apiErrorMessage}</p>
                        <button onClick={() => {
                            setIsErrorModalOpen(false);
                            setFormData({ name: '', email: '', message: '' });
                        }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactForm;
