import { Link } from 'react-router-dom';

const HomePagePanelRow = ({ heading, text, image, imageAlt, isReversed }) => {
    return (
        <div className={`panel ${isReversed ? 'reverse' : ''}`}>
            <div className="panel-text">
                <h2>{heading}</h2>
                {text}
            </div>
            <div className="panel-image">
                <img src={image} alt={imageAlt} />
            </div>
        </div>
    );
};

const HomePagePanel = () => {
    const panelsData = [
        {
            heading: "Workspace in the Heart of St. John's",
            text: (
                <p>
                    Set in a characterful Victorian building, Room 49 offers a unique co-working space in Tunbridge Wells. Just minutes from great coffee, parking, and local shops, it's designed for convenience and productivity. Whether you're a freelancer, remote worker, or business owner, you'll find a focused space away from home distractions.
                </p>
            ),
            image: '/images/workspace_heart_of_st_johns.jpg',
            imageAlt:
                "Room 49 co-working space exterior in a characterful Victorian building in Tunbridge Wells.",
        },
        {
            heading: "Bright and Airy with Natural Lighting",
            text: (
                <p>
                    With large windows that let in floods of natural light, Room 49 is bright, airy, and designed for focus. Whether you're settling in for a full day's work or just need a change of scenery, the inviting atmosphere helps you stay productive. The setting encourages fresh thinking and creativity, making it an ideal place to work and reflect.
                </p>
            ),
            image: '/images/bright_airy.jpg',
            imageAlt:
                "Bright and airy co-working space with large windows allowing natural light to flood in.",
        },
        {
            heading: "Spacious Desks in a Setting Full of Character",
            text: (
                <p>
                    Our generously sized desks give you plenty of room to work comfortably, with exposed brick, bookshelves, and plants creating an inspiring backdrop. The blend of style and function makes it easy to settle in, whether you're popping in for a quick productive session or settling down for deeper, creative thinking.
                </p>
            ),
            image: '/images/spacious_desks.jpg',
            imageAlt:
                "Spacious desks in an industrial-chic co-working space with exposed brick, bookshelves, and greenery.",
        },
        {
            heading: "A Meeting Room When You Need It",
            text: (
                <p>
                    Need a space for client calls, private discussions, or team meetings? Our basement meeting room offers a quiet, professional setting equipped with a projector for presentations and video conferencing. Whether you're brainstorming, reviewing training material, or hosting a virtual meeting, the setup provides everything you need. Available to book as an add-on when you've reserved your desk for the day.
                </p>
            ),
            image: '/images/meeting_room.jpg',
            imageAlt:
                "Private meeting room at Room 49, ideal for client calls and team discussions.",
        },
        {
            heading: "Effortless Access & Security",
            text: (
                <p>
                    Room 49 is fully self-service, with secure entry via QR codes or passcodes. No check-ins, no waiting—just a smooth, hassle-free experience. The office is equipped with fast, reliable internet and private, password-protected Wi-Fi, so you can work securely and stay connected. With security cameras and controlled door access, the space is designed to keep you safe and secure while you focus on your work.
                </p>
            ),
            image: '/images/effortless_access.jpg',
            imageAlt:
                "Secure self-service entry system at Room 49 with QR code and passcode access.",
        },
        {
            heading: "Practical Facilities to Keep You Going",
            text: (
                <p>
                    With comfortable seating, practical layouts, and a kitchenette, Room 49 has the essentials covered to help you power through the day. Complimentary basics like tea, coffee, and milk are often available too, adding an extra touch of convenience.
                </p>
            ),
            image: '/images/practical_facilities.jpg',
            imageAlt:
                "Kitchenette area in Room 49 with tea and coffee facilities for co-workers.",
        },
        {
            heading: "Simple Booking, Ready When You Are",
            text: (
                <p>
                    Booking your desk at Room 49 is quick and easy. Choose between our flexible daily or dedicated monthly options. Just select a desk from the floorplan and you're all set to work. No hassle, no waiting—just reserve and get to work. <Link to="/booking">Book your desk</Link> now and make more of your day.
                </p>
            ),
            image: '/images/simple_booking.jpg',
            imageAlt:
                "Desk booking is easy at Room 49. Choose your day and get to work.",
        },
    ];

    return (
        <div className="panels-row">
            {panelsData.map((panel, index) => (
                <HomePagePanelRow
                    key={index}
                    heading={panel.heading}
                    text={panel.text}
                    image={panel.image}
                    imageAlt={panel.imageAlt}
                    isReversed={index % 2 !== 0} // Alternate the layout for every second panel
                />
            ))}
        </div>
    );
};

export default HomePagePanel;
