import { useEffect } from 'react';
import '../components/terms/Terms.css';

const Terms = () => {

    useEffect(() => {
        // Check if the URL contains the #privacy fragment
        if (window.location.hash === '#privacy') {
            const element = document.getElementById('privacy');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="page">
            <div className='page-header'>
                <h1>Our legal terms</h1>
            </div>
            <div className='page-content'>
                <p>
                    When you book a desk, you're agreeing to these terms. So yes, you do need to read them—but we've kept things short, clear, and jargon-free to make things as painless as possible. It's all about keeping the space fair, secure, and easy to use for everyone.
                </p>
                <h2>Booking</h2>
                <p>
                    Room 49 is a self-service space—users manage their own bookings and access without direct support. • All bookings must be made online and paid in full at the time of booking. • Bookings are final and non-refundable, including cancellations, no-shows, or unused days. • Users select a specific desk when booking and must only use the desk they've booked. • The meeting room is booked separately.
                </p>
                <h2>Using the space</h2>
                <p>
                    Room 49 is a shared workspace—please be considerate of others. • Keep noise reasonable and use headphones for calls. • Some background sound, including from the building itself, is to be expected. • The kitchenette is there for everyone's use and includes tea, coffee, and milk when available—just make sure to leave it tidy for the next person. • Power sockets are intended for low-power, work-related devices only (e.g., laptops, phones). • Smoking, vaping, and illegal substances are not allowed. • We have a separately bookable basement room. It's away from the main workspace, so ideal for having a meeting or a conversation with someone. • Children and pets are not permitted in the workspace, except for registered service animals.
                </p>
                <h2>Security</h2>
                <p>
                    Do not share your access or let others into the space—this is a serious breach of security. • Cameras are in place throughout the space (no audio), and recordings are kept for one week. • If you're the last one out, make sure the lights are switched off and the door closes fully behind you. • The internet is fast and secure, but like any shared network, it's used at your own risk. • When handling sensitive business data, we would always recommend using a VPN. • You may leave personal items if you have a monthly booking, but this is entirely at your own risk—we can't take responsibility for anything lost, damaged, or unattended.
                </p>
                <h2>Legal</h2>
                <p>
                    We trust everyone to follow the rules and respect the space. • If we receive complaints, warnings may be issued via email. • In serious or repeated cases, access may be withdrawn without refund. • Room 49 is not responsible for any illegal activity on the premises. • While we do everything we can to maintain a safe environment, Room 49 isn't responsible for accidents or injuries unless legally required.
                </p>
                <h2 id='privacy'>Privacy</h2>
                <p>
                    We collect only the personal information needed to manage bookings—just your name and email address. • We do not share your data with third parties or use it for marketing. • Your data is securely stored and only accessible to authorised personnel. • You can request access, correction, or deletion of your personal data in line with UK GDPR. • To make a request, contact us via our website.
                </p>
                <p className='date-updated'>
                    Last updated: 2 April 2025
                </p>
            </div>
        </div>
    );
};

export default Terms;
