import './PersonalInfoForm.css';

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


const PersonalInfoForm = forwardRef(({
    // pass the personal in here as we have got here from a reload of the booking form
    personalInfo,
    setPersonalInfo,
}, ref) => {

    const [validationErrors, setValidationErrors] = useState({});
    const [triggerValidation, setTriggerValidation] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);


    // handles the first name changes
    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;

        setPersonalInfo({
            ...personalInfo,
            [name]: type === 'checkbox' ? checked : value,
        });

        if (isButtonClicked) {
            // continuously validate form on input change
            setTriggerValidation(true);
        }

    };


    const validateForm = () => {

        if (!isButtonClicked) {
            // capture first time button has been clicked
            setIsButtonClicked(true);
        }

        const errors = {};
        if (!personalInfo.first_name) {
            errors.first_name = true;
        }
        if (!personalInfo.last_name) {
            errors.last_name = true;
        }
        if (!personalInfo.email) {
            errors.email = true;
        } else if (!/\S+@\S+\.\S+/.test(personalInfo.email)) {
            errors.email = true;
        }
        if (!personalInfo.termsAccepted) {
            errors.termsAccepted = 'You must accept the terms and conditions';
        }
        setValidationErrors(errors);

        // determine if form is valid
        const isValid = Object.keys(errors).length === 0

        // Reset trigger after validation so it will be called again if set true
        if (triggerValidation)
            setTriggerValidation(false);

        return isValid;

    }

    useEffect(() => {
        if (triggerValidation) {
            validateForm();
        }
    }, [triggerValidation]);

    // Expose validateForm to parent via ref
    useImperativeHandle(ref, () => ({
        validateForm
    }));

    return (
        <div className='personal-form-container'>
            <div className='input-container'>
                <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="First name"
                    value={personalInfo.first_name}
                    onChange={handleInputChange}
                    aria-label="Enter your first name"
                />
                {validationErrors.first_name && (
                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                )}
            </div>

            <div className='input-container'>
                <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Last name"
                    value={personalInfo.last_name}
                    onChange={handleInputChange}
                    aria-label="Enter your last name"
                />
                {validationErrors.last_name && (
                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                )}
            </div>

            <div className='input-container'>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={personalInfo.email}
                    onChange={handleInputChange}
                    aria-label="Enter your email"
                />
                {validationErrors.email && (
                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                )}
            </div>

            <div className='input-container'>
                <label className='checkbox-container'>
                    <input className='checkbox'
                        type="checkbox"
                        id="termsAccepted"
                        name="termsAccepted"
                        checked={personalInfo.termsAccepted}
                        onChange={handleInputChange}
                    />
                    <span>I accept your <a href="/terms" target='_blank' rel='noopener noreferrer' >terms and conditions</a></span>

                </label>
                {validationErrors.termsAccepted && (
                    <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                )}
            </div>
        </div>
    );
});

export default PersonalInfoForm;
