import './Contact.css';

const ContactMap = () => {
    return (
        <div className="map-container">
            {/* Embed your map here */}
            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2511.1234567890123!2d0.26008!3d51.140788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df1234567890ab%3A0xcdef1234567890ab!2s49%20St%20Johns%20Road%2C%20Tunbridge%20Wells%20TN4%209TP%2C%20UK!5e0!3m2!1sen!2sus!4v1614833833319!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
            ></iframe>
        </div>
    );
};

export default ContactMap;
