import './App.css';

import '@fortawesome/fontawesome-free/css/all.min.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import HomePage from './pages/HomePage';
import BookingPage from './pages/BookingPage';
import MeetingPage from './pages/MeetingPage';
import FAQPage from './pages/FAQPage';
import ContactPage from './pages/ContactPage';
import AboutUs from './pages/AboutUs';
import Terms from './pages/Terms';
import Footer from './components/footer/Footer';
import BookingConfirmationPage from './pages/BookingConfirmationPage';

const App = () => (
    <div className="App">
        <Router>
            <div className='app-container'>
                <Navbar />
                <div className='content'>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/booking" element={<BookingPage />} />
                        <Route path="/meeting" element={<MeetingPage />} />
                        <Route path="/faq" element={<FAQPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route
                            path="/booking-confirmation/:bookingGroupID"
                            element={<BookingConfirmationPage />}
                        />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    </div>
);

export default App;
