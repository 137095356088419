import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const PayPalButton = ({
	amount,
	group_booking_id,
	setIsErrorModalOpen,
	setApiErrorMessage
}) => {

	const paypalRef = useRef();
	const navigate = useNavigate();

	const API_URL = process.env.REACT_APP_API_URL || "/api";
	const bookingsURL = `${API_URL}/bookings/confirmpayment`;

	useEffect(() => {
		if (!window.paypal || !paypalRef.current) return;

		const paypalButtons = window.paypal.Buttons({
			createOrder: (data, actions) => {
				return actions.order.create({
					purchase_units: [{
						amount: {
							value: amount.toFixed(2), // Always 2 decimal places
							currency_code: 'GBP'
						},
						custom_id: group_booking_id
					}]
				});
			},
			onApprove: async (data, actions) => {
				const order = await actions.order.capture();
				console.log('Order successful:', order);

				await fetch(bookingsURL, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						group_booking_id: group_booking_id,
						paypal_order: order,
					}),
				});

				navigate(`/booking-confirmation/${group_booking_id}`);

			},
			onError: (err) => {
				// catch any errors
				setApiErrorMessage('Apologies but something went wrong. Please try again later. ' + err.message);
				setIsErrorModalOpen(true);
				console.error('PayPal Checkout error:', err);
			}
		});

		paypalButtons.render(paypalRef.current);

		return () => {
			paypalButtons.close(); // Clean up on unmount
		};
	}, [amount]);

	return <div ref={paypalRef} />;
};

export default PayPalButton;
