import { format, parseISO } from 'date-fns';
import './BookingConfirmation.css';

const BookingConfirmationTable = ({ finalDesks, totalPrice, bookingType }) => {

    const sortedKeys = Object.keys(finalDesks).sort((a, b) => new Date(a) - new Date(b));

    return (
        <div className='booking-confirmation-table'>
            {bookingType === "daily" && (
                <table>
                    <tbody>
                        {sortedKeys.map((date) => {
                            const desk = finalDesks[date];
                            return (
                                <tr key={date}>
                                    <td>{format(parseISO(date), "EEE d MMM yyyy")} (7am-7pm)</td>
                                    <td>Desk {desk.id}</td>
                                    <td>£{desk.price}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td><strong>Total</strong></td>
                            <td><strong>£{totalPrice}</strong></td>
                        </tr>
                    </tfoot>
                </table>
            )
            }
            {
                bookingType === "monthly" && (
                    <table>
                        <tbody>
                            {sortedKeys.map((date) => {
                                const desk = finalDesks[date];
                                return (
                                    <tr key={date}>
                                        <td>{format(parseISO(date), "MMM yyyy")} (weekdays: 7am-7pm)</td>
                                        <td>Desk {desk.id}</td>
                                        <td>£{desk.price}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td><strong>Total</strong></td>
                                <td><strong>£{totalPrice}</strong></td>
                            </tr>
                        </tfoot>
                    </table>
                )
            }
        </div >
    );
}

export default BookingConfirmationTable;