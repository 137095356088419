import '../components/homepage/HomePage.css';

import HomePageBanner from '../components/homepage/HomePageBanner';
import HomePagePanel from '../components/homepage/HomePagePanel';

const HomePage = () => {
	return (
		<div>
			<HomePageBanner />
			<div className='page'>
				<HomePagePanel />
			</div>
		</div>
	);
};

export default HomePage;
