import '../components/about/AboutUs.css';

const AboutUs = () => {

    return (
        <div className="page">
            <div className='about-us-container'>
                <div className='page-header'>
                    <h1>A bit about us</h1>
                </div>
                <div className='page-content'>
                    <div className="about-us-image">
                        <img src='/images/about_us.jpg' alt='The Room 49 story' />
                    </div>
                    <p>
                        You might be wondering how Room 49 came about. It's not your typical co-working space—that's kind of the point. It started as a bit of a passion project that turned into something more. So if you're curious, here's the story and the people behind it.
                    </p>
                    <p>
                        We're a husband and wife team, living in the St John's area of Tunbridge Wells. We set out to find ourselves an office, not run a co-working business. But when this space became available, we couldn't resist. We stripped it back to bare brick, wooden floors, and metal beams—the kind of Victorian-style building we'd always wanted to work in. As it all came together, we realized we had more desks, more space, and frankly, a much cooler setup than we needed. So we opened it up to others who wanted the same.
                    </p>
                    <p>
                        That's how Room 49 was born. A simple, stylish space for professionals who want a more focused, intentional place to work—without the distractions of home, the faff of a lease, or the background hum of a space designed for socializing rather than working. Big desks, fast Wi-Fi, and a setup that makes work feel better. No memberships, no distractions—just a great place to get things done.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
