import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enGB from 'date-fns/locale/en-GB'; // Import the UK locale

import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
	'en-GB': enGB, // Use the imported UK locale
};


const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales,
});


const MyCalendar = (props) => (
	<div className="myCustomHeight">
		<Calendar
			localizer={localizer}
			// events={myEventsList} // You'll need to provide your events
			//startAccessor="start"
			//endAccessor="end"
			defaultView='week'
			//style={{ height: 1000, margin: "50px" }}
			views={['week']}
		/>
	</div>
);

const MeetingPage = () => {
	return (
		<div className="page">
			<div className="page-header">
				<h1>Book the basement</h1>
			</div>
			<div className='page-content'>
				<p>
					The meeting room is a private space in the basement with a meeting table, sofa, whiteboard and projector. It's only available if you've booked a desk upstairs. Just reserve your slot through the system and share your code if you're expecting a guest.
				</p>
			</div>
			<MyCalendar />
		</div>
	);
};

export default MeetingPage;