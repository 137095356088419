import BookingForm from '../components/booking/BookingForm';

const BookingPage = () => {
	return (
		<div className="page">
			<div className="page-header">
				<h1>Book a desk</h1>
			</div>
			<div className='page-content'>
				<p>
					Room 49 offers daily or monthly desks, depending on your needs. Both include fast Wi-Fi and a calm, comfortable setup. Monthly desks come with wired internet and space for extra screens. No faff, no front desk. The floorplan below is for reference only—please click the desk number buttons underneath to choose your spot for each day.
				</p>
				<BookingForm />
			</div>
		</div>
	);
};

export default BookingPage;
