import './AvailabilityForm.css'

import React, { useState, useEffect, useRef } from 'react';
import BookingTypeButton from './BookingTypeButton';
import DailyCalendar from './DailyCalendar';
import MonthlyCalendar from './MonthlyCalendar';

const AvailabilityForm = ({
    handleBookingTypeButtonClick,
    bookingType,
    bookingDates,
    handleDailySelect,
    handleMonthlySelect,
    triggerBookingDatesValidation,
    SetBookingDatesValid,
}) => {

    const [errorMessage, setErrorMessage] = useState({});
    const calendarRef = useRef(null); // Create a reference for the booking-calendar div

    // Function to scroll to the booking-calendar div
    const scrollToCalendar = () => {
        if (window.innerWidth <= 768 && calendarRef.current) { // Check if in mobile view
            setTimeout(() => {
                calendarRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 150); // Delay to ensure DOM updates
        }
    };

    // validate the booking dates based on the booking type
    const validateBookingDates = () => {

        const errors = {};

        // check the booking type is set
        if (!bookingType) {
            errors.booking_type = true
        }

        if (bookingDates.length === 0) {
            errors.booking_dates = true;
        }

        setErrorMessage(errors);
        SetBookingDatesValid(Object.keys(errors).length === 0);
    };

    useEffect(() => {
        if (triggerBookingDatesValidation) {
            validateBookingDates();
        }
    }, [triggerBookingDatesValidation, validateBookingDates]);


    return (
        <div>
            <div className='calendar-selection-container'>

                <div className='calendar-selector-item'>
                    <BookingTypeButton
                        onClick={() => {
                            handleBookingTypeButtonClick("daily");
                            scrollToCalendar();
                        }}
                        isSelected={bookingType === "daily"}
                        booking_type="daily"
                        button_text={"Daily"}
                        description="A flexible daily space to plug in your laptop and get to work. Includes high-speed Wi-Fi and a comfortable setup—perfect for short-term use.">
                    </BookingTypeButton>
                </div>
                <div className='calendar-selector-item'>
                    <BookingTypeButton
                        onClick={() => {
                            handleBookingTypeButtonClick("monthly");
                            scrollToCalendar();
                        }}
                        isSelected={bookingType === "monthly"}
                        booking_type="monthly"
                        button_text={"Monthly"}
                        description="A dedicated monthly desk for a more permanent setup. Ideal for those who need extra screens, wired internet, or a fixed workspace.">
                    </BookingTypeButton>
                </div>
                <div className='calendar-selector-item' ref={calendarRef} >
                    <div className="booking-calendar">
                        {(bookingType === "" || bookingType === "daily") && (
                            <DailyCalendar
                                handleDailySelect={handleDailySelect}
                                isSelected={bookingType === "daily"}
                                isError={errorMessage.booking_dates}>
                            </DailyCalendar>
                        )}
                        {bookingType === "monthly" && (
                            <MonthlyCalendar
                                handleMonthlySelect={handleMonthlySelect}
                                isSelected={bookingType === "monthly"}
                                isError={errorMessage.booking_dates}>
                            </MonthlyCalendar>
                        )}
                    </div >
                </div>
            </div>
            {(bookingType === "daily") && (
                <div className="floor-plan">
                    <h2>Office Floorplan</h2>
                    <img src='/images/floorplan_daily.png' alt='The Room 49 office daily desks floorplan' />
                </div>
            )
            }
            {(bookingType === "monthly") && (
                <div className="floor-plan">
                    <h2>Office Floorplan</h2>
                    <img src='/images/floorplan_monthly.png' alt='The Room 49 office monthly desks floorplan' />
                </div>
            )}
        </div >
    );
};

export default AvailabilityForm;
