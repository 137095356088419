import { useEffect, useState } from "react";

const BookingCountdown = ({ resetAfterTimeOutOrError }) => {

    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds

    useEffect(() => {
        if (timeLeft <= 0) {
            resetAfterTimeOutOrError();
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);


    const formatTime = (seconds) => {
        const m = Math.floor(seconds / 60);
        const s = seconds % 60;
        return `${String(m).padStart(2, "0")}:${String(s).padStart(2, "0")}`;
    };


    return (
        <div className="booking-countdown">
            Time left to complete your booking: {formatTime(timeLeft)}
        </div>
    );
};

export default BookingCountdown;
