import React, { useEffect, useState, useRef } from 'react';

import './BookingForm.css';

import { getMonthsBetween } from '../../utils/dateUtils'; // Adjust the path as necessary
import AvailabilityForm from './AvailabilityForm';
import AvailabilitySummary from './AvailabilitySummary';
import BookingConfirmationTable from '../bookingconfirmation/BookingConfirmationTable';
import PersonalInfoForm from './PersonalInfoForm';
import PayPalButton from "../paypal/PayPalButton";
import BookingCountdown from './CountDown';

const BookingForm = () => {

    // local development uses env.development, production routes to /api
    const API_URL = process.env.REACT_APP_API_URL || "/api";

    // "daily" or "monthly"
    const [bookingType, setBookingType] = useState('');

    // booking_dates store the selected dates from the date picker/calendar
    // if a daily booking, then these are the actual days
    // if a monthly booking, then these are the first day of each month
    // these are the DateObject of the data picker
    const [bookingDates, setBookingDates] = useState([]);

    // these store the user's selected days and months
    // for daily bookings, selected_desks_daily will store the selected desks for each day
    // for monthly bookings, selected_desks_monthly will store the first day of each month
    // these are formatted dates in the format "YYYY-MM-DD"
    const [finalDesks, setFinalDesks] = useState({});

    // total price calculated from the selected desks
    // updates when the user selects a desk
    const [totalPrice, setTotalPrice] = useState(0);

    const [personalInfo, setPersonalInfo] = useState({
        first_name: '',
        last_name: '',
        email: '',
        termsAccepted: false,
    });

    const [isBookingDatesValid, setIsBookingDatesValid] = useState(false);
    const [triggerBookingDatesValidation, setTriggerBookingDatesValidation] = useState(false);

    const [isShowAvailabilityPanel, setIsShowAvailabilityPanel] = useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [isShowPayPalPanel, setIsShowPayPalPanel] = useState(false);

    // group_booking_id is the booking ID returned from the backend API
    // it is the idea that identifies the booking and groups together
    // all days/desks in a single booking
    // this is used to identify the booking when the user makes a payment
    const [group_booking_id, setGroupBookingID] = useState(null);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    // Create a reference for smooth transition to the booking calendar
    const calendarRef = useRef(null); // Create a reference for the booking calendar div
    const deskRef = useRef(null); // Create a reference for the desk div
    const summaryRef = useRef(null); // Create a reference for the desk div

    // Function to scroll to the booking-calendar div
    const scrollToCalendar = () => {
        if (calendarRef.current) { // Check if in mobile view
            setTimeout(() => {
                calendarRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 250); // Delay to ensure DOM updates
        }
    };

    // Function to scroll to the booking-calendar div
    const scrollToDesks = () => {
        if (deskRef.current) { // Check if in mobile view
            setTimeout(() => {
                deskRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 250); // Delay to ensure DOM updates
        }
    };

    // Function to scroll to the booking-calendar div
    const scrollToSummary = () => {
        if (summaryRef.current) { // Check if in mobile view
            setTimeout(() => {
                summaryRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 250); // Delay to ensure DOM updates
        }
    };


    const delete_group_booking = async (group_booking_id) => {

        try {

            const bookingsURL = `${API_URL}/bookings/${group_booking_id}`;

            const response = await fetch(bookingsURL, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {

                let apiErrorMsg = `Failed to delete booking: ${response.status} ${response.statusText}`;

                if (response.status === 400) {
                    apiErrorMsg = 'Bad Request: Please check the data and API call.';
                }
                else if (response.status === 404) {
                    apiErrorMsg = 'Resource error: The requested resource does not exist on the server.';
                }
                else if (response.status === 500) {
                    apiErrorMsg = 'Server Error: Please try again later.';
                }
                console.error(apiErrorMsg);
                throw new Error(apiErrorMsg);
            }

            const data = await response.json();
            console.log('Deleted booking:', data);

        } catch (error) {
            // catch any errors, i.e. ones not caught by the fetch call
            // and also the one thrown by the fetch call
            setApiErrorMessage('Apologies but something went wrong. Please try again later. ' + error.message);
            setIsErrorModalOpen(true);
            console.log('Error deleted group booking: ', error.message);
        }
    };

    const ResetAfterDailyMonthlySelectionChange = () => {

        // this is like a full reset of the booking form
        // when the user changes the booking type, we need to reset everything

        if (bookingDates.length > 0) {
            setBookingDates([]);
        }

        if (Object.keys(finalDesks).length > 0) {
            setFinalDesks({});
        }

        if (totalPrice) {
            setTotalPrice(0);
        }

        if (isBookingDatesValid) {
            setIsBookingDatesValid(false);
        }

        if (group_booking_id) {

            // call API to delete booking
            delete_group_booking(group_booking_id)

            setIsProcessingPayment(false);
            setGroupBookingID(null);
        }

        scrollToCalendar();

    }

    const ResetAfterDatesChange = () => {

        // we don't need to reset the booking type, just the dates
        // when the user changes the dates, we need to reset the desks
        // and the total price

        if (Object.keys(finalDesks).length > 0) {
            setFinalDesks({});
        }

        if (totalPrice) {
            setTotalPrice(0);
        }

        if (isBookingDatesValid) {
            setIsBookingDatesValid(false);
        }

        if (group_booking_id) {

            // call API to delete booking
            delete_group_booking(group_booking_id)

            setIsProcessingPayment(false);
            setGroupBookingID(null);
        }

        scrollToDesks();

    }

    const ResetAfterDeskSelectChange = () => {

        // when the user changes the desk selection, we only need
        // to reset the booking object in the database
        // and get a new group_booking_id for their new desk selection

        if (group_booking_id) {

            // call API to delete booking
            delete_group_booking(group_booking_id)

            setIsProcessingPayment(false);
            setGroupBookingID(null);
        }
    }

    const ResetAfterPersonalInfoChange = () => {

        if (group_booking_id) {

            // call API to delete booking
            delete_group_booking(group_booking_id)

            setIsProcessingPayment(false);
            setGroupBookingID(null);
        }
    }

    const resetAfterTimeOutOrError = () => {

        // like swithcing the booking type, we need to reset the booking details
        // if the user has not completed the booking in the time allowed
        // this is like a full reset of the booking form

        if (bookingDates.length > 0) {
            setBookingDates([]);
        }

        if (Object.keys(finalDesks).length > 0) {
            setFinalDesks({});
        }

        if (totalPrice) {
            setTotalPrice(0);
        }

        if (isBookingDatesValid) {
            setIsBookingDatesValid(false);
        }

        if (group_booking_id) {

            // call API to delete booking
            delete_group_booking(group_booking_id)

            setIsProcessingPayment(false);
            setGroupBookingID(null);
        }

        scrollToCalendar();

    }

    // handles the booking type button clicks, ether daily or monthly
    const handleBookingTypeButtonClick = (bookingType) => {

        // reset the booking details
        setBookingType(bookingType);

        // reset all the other variables
        ResetAfterDailyMonthlySelectionChange();

    };

    // handles the daily date selection
    const handleDailySelect = (dates) => {

        // it is important that the dates are formatted as "YYYY-MM-DD"
        // this ensures that the dates are captured correctly as the user selects them
        // amd they are not converted to UTC. We need to capture the date the user sees/selects
        setBookingDates(dates.map((date) => date.format("YYYY-MM-DD")));
        setBookingType("daily");

        // trigger validation of the dates
        setTriggerBookingDatesValidation(true);

        // reset the other variables, user must reselect the desks
        ResetAfterDatesChange();

        // scroll to the desks
        scrollToDesks();

    };

    // handles the monthly date selection
    const handleMonthlySelect = (months) => {

        // Note the data picker/calendar sends a first and last month if the user select a ranges of months
        // not the full list of months between the start and end
        // so we need to get the full list of months between the start and end

        let list_of_months = [];

        if (months.length === 1) {
            // if length of booking_months is 1, then we just have a single month
            // just need the first day of the month
            list_of_months = months;
        }
        else if (months.length === 2) {
            // if length of booking_months is 2, then we have a range of months
            // get all the months between start and end
            list_of_months = getMonthsBetween(months[0], months[1]);
        }
        else {
            console.log('Expected month values, got:', months.length);
            throw new Error('Failed to get booking_dates');
        }

        // it is important that the dates are formatted as "YYYY-MM-DD"
        // this ensures that the dates are captured correctly as the user selects them
        // and they are not converted to UTC. We need to capture the date the user sees/selects

        setBookingDates(list_of_months.map((month) => month.format("YYYY-MM-DD")));
        setBookingType("monthly");

        // trigger validation of the dates
        setTriggerBookingDatesValidation(true);

        // reset the other variables, user must reselect the desks
        ResetAfterDatesChange();

        // scroll to the desks
        scrollToDesks();

    };


    const SetBookingDatesValid = (isValid) => {

        setIsBookingDatesValid(isValid)

        // Reset trigger after validation so it will be called again if set true
        setTriggerBookingDatesValidation(false);
    };

    const personalInfoFormRef = useRef();

    const handleSubmitForPayment = async () => {

        // Call validateForm in PersonalInfoForm
        if (personalInfoFormRef.current.validateForm()) {
            // Proceed with payment submission
            console.log('Personal info is valid. Proceeding to payment...', personalInfo);

            const payload = {

                // personal info
                first_name: personalInfo.first_name,
                last_name: personalInfo.last_name,
                email: personalInfo.email,

                // booking info
                // only one office is available for booking
                office_id: 1,
                booking_type: bookingType,
                booking_dates: finalDesks,
                // pass back the total price, to ensure the user has not tampered with the price
                // this total price will need to match the total price calculated on the server
                // it won't actually be stored in the database
                total_price: totalPrice,
            };

            try {

                const bookingsURL = `${API_URL}/bookings`;

                const response = await fetch(bookingsURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {

                    let apiErrorMsg = `Failed to post booking: ${response.status} ${response.statusText}`;

                    if (response.status === 400) {
                        apiErrorMsg = 'Bad Request: Please check the data and API call.';
                    }
                    else if (response.status === 404) {
                        apiErrorMsg = 'Resource error: The requested resource does not exist on the server.';
                    }
                    else if (response.status === 500) {
                        apiErrorMsg = 'Server Error: Please try again later.';
                    }
                    console.error(apiErrorMsg);
                    throw new Error(apiErrorMsg);
                }

                const data = await response.json();
                setGroupBookingID(data.group_booking_id);
                setIsProcessingPayment(true);

            } catch (error) {
                // catch any errors, i.e. ones not caught by the fetch call
                // and also the one thrown by the fetch call
                setApiErrorMessage('Apologies but something went wrong. Please try again later. ' + error.message);
                setIsErrorModalOpen(true);
                console.log('Error submitting for payment: ', error.message);
            }


        } else {
            return;
        }
    };


    useEffect(() => {
        // the isProcessingPayment flag is set when the user has submitted the form
        // and the booking has been created in the database
        // if the flag is set, then we can proceed with the payment
        if (isProcessingPayment) {
            setIsShowPayPalPanel(true);
        }
        else {
            setIsShowPayPalPanel(false);
        }
    }, [isProcessingPayment]);

    useEffect(() => {
        if (isBookingDatesValid) {
            // Proceed with form submission or further processing
            // show the confirmation panel
            setIsShowAvailabilityPanel(true);
        }
        else {
            // hide the confirmation panel
            setIsShowAvailabilityPanel(false);
        }
    }, [isBookingDatesValid]);

    useEffect(() => {

        // another scenario where we need to get the user to reselect the desks
        // if the final selected desks change, i.e. someone changes the desk choice
        // then we need to create a new booking in the database for this, and
        // get a new group_booking_id
        ResetAfterDeskSelectChange();

        scrollToSummary();

    }, [finalDesks]);

    useEffect(() => {

        // if the personal info changes, then we need to determine if the user
        // has changed the personal info after the booking has been created
        // if they have, then we need to delete the booking and get them to re-click the payment button
        // to create a new booking (with the new personal info)
        ResetAfterPersonalInfoChange();

    }, [personalInfo]);




    return (
        <div>
            <div className="booking-selectors" ref={calendarRef}>

                <AvailabilityForm
                    handleBookingTypeButtonClick={handleBookingTypeButtonClick}
                    bookingType={bookingType}
                    bookingDates={bookingDates}
                    handleDailySelect={handleDailySelect}
                    handleMonthlySelect={handleMonthlySelect}
                    triggerBookingDatesValidation={triggerBookingDatesValidation}
                    SetBookingDatesValid={SetBookingDatesValid}
                />
            </div>
            <div className="booking-selections" ref={deskRef}>
                {isShowAvailabilityPanel && (
                    <AvailabilitySummary
                        bookingType={bookingType}
                        bookingDates={bookingDates}
                        setFinalDesks={setFinalDesks}
                        setTotalPrice={setTotalPrice}
                        resetAfterTimeOutOrError={resetAfterTimeOutOrError}
                    />
                )}
            </div>
            <div>
                {Object.keys(finalDesks).length > 0 && (
                    <div className='selected-desks-container' ref={summaryRef}>
                        <h2>Your booking summary</h2>
                        <BookingConfirmationTable
                            finalDesks={finalDesks}
                            totalPrice={totalPrice}
                            bookingType={bookingType}
                        />
                        <h2>Personal details</h2>
                        <PersonalInfoForm
                            ref={personalInfoFormRef}
                            // Pass other props as needed
                            personalInfo={personalInfo}
                            setPersonalInfo={setPersonalInfo}
                        />
                        <div className='booking-submit-button-container'>
                            <button
                                type="submit"
                                onClick={() => handleSubmitForPayment()}>
                                Make payment
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {isShowPayPalPanel && group_booking_id && totalPrice > 0 && (
                    <div className='payment-container'>
                        <h2>Payment details</h2>
                        <BookingCountdown resetAfterTimeOutOrError={resetAfterTimeOutOrError} />
                        <div className='payment-details-container'>
                            <div className='payment-details'>
                                <p>
                                    You're nearly there. You've selected your desk days and entered your details—now it's time to complete your booking. We use PayPal to handle payments securely. You don't need a PayPal account—just choose the option that works best for you below.
                                </p>
                            </div>
                            <div className='payment-details'>
                                <PayPalButton
                                    amount={totalPrice}
                                    group_booking_id={group_booking_id}
                                    setIsErrorModalOpen={setIsErrorModalOpen}
                                    setApiErrorMessage={setApiErrorMessage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isErrorModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <p>{apiErrorMessage}</p>
                        <button onClick={() => {
                            setIsErrorModalOpen(false);
                            resetAfterTimeOutOrError();
                        }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingForm;
