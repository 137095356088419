import { Calendar, DateObject } from 'react-multi-date-picker';

// Function to get an array of months between two dates

export const getMonthsBetween = (startDate, endDate) => {
	let start = new Date(startDate);
	let end = new Date(endDate);

	if (start > end) {
		throw new Error('Start date must be earlier than end date');
	}

	let months = [];
	const startYear = start.getFullYear();
	const startMonth = start.getMonth();
	const endYear = end.getFullYear();
	const endMonth = end.getMonth();

	for (let year = startYear; year <= endYear; year++) {
		let month = year === startYear ? startMonth : 0;
		const monthLimit = year === endYear ? endMonth : 11;

		for (; month <= monthLimit; month++) {
			// create a new DateObject from new Date(year, month, 1)
			// and push it to the months array
			const _date = new DateObject()
			_date.setYear(year)
			_date.setMonth(month + 1) // month is 0-indexed
			_date.setDay(1);
			months.push(_date);
		}
	}

	return months;
};
